import { render, staticRenderFns } from "./TableHeaderLabels.vue?vue&type=template&id=72449f43&scoped=true&"
import script from "./TableHeaderLabels.vue?vue&type=script&lang=js&"
export * from "./TableHeaderLabels.vue?vue&type=script&lang=js&"
import style0 from "./TableHeaderLabels.vue?vue&type=style&index=0&id=72449f43&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72449f43",
  null
  
)

export default component.exports