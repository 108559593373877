<template>
  <div>
    <b-card>
      <table-spinner :loading="stateLoading"></table-spinner>
      <b-table
        v-if="!stateLoading"
        id="email-template"
        class="position-relative"
        :items="data"
        responsive
        primary-key="id"
        :fields="fields"
        show-empty
        empty-text="No matching records found"
      >
        <template #head()="fields">
          <table-header-labels
            :fields="fields"
            :filter="filter"
            @onSort="setOrdering"
          ></table-header-labels>
        </template>
        <template #cell(actions)="data">
          <b-button @click="goToEditPage(data.item)" variant="outline-primary">
            Edit
          </b-button>
        </template>
        <template #cell(created_at)="data">
          {{ $dateHelpers.formatDate(data.item.created_at) }}
        </template>
        <template #cell(updated_at)="data">
          {{ $dateHelpers.formatDate(data.item.updated_at) }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import { BTable, BButton, BCard } from "bootstrap-vue";
import TableSpinner from "@/layouts/components/TableSpinner.vue";
import debounce from "lodash.debounce";
import { mapActions } from "vuex";
import TableHeaderLabels from "@/layouts/components/TableHeaderLabels.vue";
export default {
  components: {
    TableSpinner,
    BTable,
    BButton,
    BCard,
    TableHeaderLabels,
  },
  data() {
    return {
      fields: [
        { key: "code", label: "Code" },
        { key: "subject", label: "Subject" },
        { key: "updated_at", label: "Updated at" },
        { key: "actions", label: "" },
      ],
      loading: false,
    };
  },
  computed: {
    filter() {
      return {
        ...this.$route.query,
        sort_by: this.$route.query?.sort_by ?? "code",
        order_by: this.$route.query?.order_by ?? "asc",
      };
    },
    data() {
      return this.$store.getters["adminEmailTemplate/getEmailTemplateData"];
    },
    stateLoading() {
      return this.$store.getters["adminEmailTemplate/isLoading"];
    },
  },
  mounted() {
    this.getEmailTemplate().catch(console.error);
  },
  methods: {
    ...mapActions({
      getEmailTemplate: "adminEmailTemplate/getEmailTemplate",
    }),
    setOrdering(filter) {
      this.$router.replace({ query: { ...this.filter, ...filter } });
      this.onRefreshPage();
    },
    goToEditPage(data) {
      this.$router.push({ name: "email-template", params: { id: data.id } });
    },
    onRefreshPage: debounce(async function () {
      this.loading = true;
      try {
        await this.getEmailTemplate(this.filter);
      } finally {
        this.loading = false;
      }
    }, 300),
  },
};
</script>
