<template>
  <div class="table-header">
    <div
      class="d-flex justify-content-between align-items-center cursor-pointer"
      v-if="
        fields.field.label != '' && filter && fields.field.sortable !== false
      "
      @click="onSort(fields.field.key)"
    >
      <div>{{ fields.field.label }}</div>
      <div class="d-flex flex-wrap">
        <feather-icon
          icon="ChevronUpIcon"
          size="14"
          class="w-100"
          :class="
            orderBy === 'asc' &&
            filter.sort_by === fields.field.key &&
            'text-success'
          "
        />
        <feather-icon
          icon="ChevronDownIcon"
          size="14"
          class="w-100"
          :class="
            orderBy === 'desc' &&
            filter.sort_by === fields.field.key &&
            'text-success'
          "
        />
      </div>
    </div>
    <div v-else class="table-header-label">{{ fields.field.label }}</div>
  </div>
</template>

<script>
export default {
  props: {
    filter: {
      type: Object,
    },
    fields: {
      type: Object,
    },
  },
  computed: {
    orderBy() {
      return this.filter.order_by;
    },
  },
  methods: {
    onSort(column) {
      const orderBy = this.filter.order_by === "desc" ? "asc" : "desc";
      this.$emit("onSort", {
        sort_by: column,
        order_by: orderBy,
      });
    },
  },
};
</script>
<style scoped>
.table-header {
  min-width: 150px;
}
.table-header-label {
  margin-top: 5px;
}
</style>
